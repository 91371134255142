.devIcons {
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 8px;
  text-align: center;
}

.softwareSkillsInline {
  display: inline-block;
  margin-top: 16px;
  margin-right: 16px;
}

.softwareSkillsInline > i {
  color: #868e96;
}

.softwareSkillsInline > i:hover {
  color: #645beb;
}
.softwareSkillsInline > p {
  color: #868e96;
  font-size: 10px;
}
.softwareSkillsInline > i:hover ~ p{
  color: #645beb;
}
